myApp.controller('headerController', function ($scope, $location, $log, $cookies, $uibModal, TranslationService, UsersService) {

    if ($cookies.get('language_code')) {
        TranslationService.getTranslation($scope, $cookies.get('language_code'));
    } else {
        TranslationService.getTranslation($scope, "en");
    }

    $scope.userStatus = UsersService.userStatus;

    $scope.regcodes = function () {
        $location.path('/regcodes');
    }

    $scope.customers = function () {
        $location.path('/customers');
    }

    $scope.logout = function () {

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirmdialog.html',
            controller: 'confirmdialogController',
            resolve: {
                dialogTitle: function () {
                    return $scope.translation.LOGOUT;
                },
                dialogMessage: function () {
                    return $scope.translation.LOGOUT_TEXT;
                }
            }
        });

        modalInstance.result.then(function (dialogResult) {
            if (dialogResult == 1) {
                UsersService.logout();
                $location.path('/');
            }
        });
    }
    
$scope.getClass = function (path1, path2) {
    if (path2) {
        return ($location.path().substr(0, path1.length) === path1 || $location.path().substr(0, path2.length) === path2) ? 'nav-cust-active' : '';
    } else {
        return ($location.path().substr(0, path1.length) === path1) ? 'nav-cust-active' : '';
    }
}
    
});
