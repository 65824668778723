myApp.service('RegCodesHistoryService', function ($http, $cookies) {

	this.getRegistrationHistory = function (REG_CODE_HIST_REG_ID) {
		if ($cookies.get('logged_in') && $cookies.get('authcode')) {
			var query = { "REG_CODE_HIST_REG_ID": REG_CODE_HIST_REG_ID };
			return $http.post("/regcodeshistory/getByQuery", { 'auth': $cookies.get('authcode'), 'query': query }).then(function (response) {
				return response.data;
			}, function (response) {
				alert("Error retrieving registration codes change history.");
			});
		} else {
			alert("Error retrieving registration codes change history.");
		}
	}

	this.saveRegistrationHistory = function (history) {
		if ($cookies.get('logged_in') && $cookies.get('authcode')) {
			return $http.post("/regcodeshistory/saveData", { 'auth': $cookies.get('authcode'), 'data': history }).then(function (response) {
				return response.data;
			}, function (response) {
				console.error(response);
				return false;
			});
		} else {
			return false;
		}

	};

});