myApp.service('RegCodeChangesService', function ($http, $cookies) {

    this.getRegistrationCodeChangeReasons = function () {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            return $http.post("/regcodechanges/getAll", { 'auth': $cookies.get('authcode') }).then(function (response) {
                return response.data;
            }, function (response) {
                alert("Error retrieving registration codes change reasons.");
            });
        } else {
            alert("Error retrieving registration codes change reasons.");
        }
    }

});