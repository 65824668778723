angular.module('myApp')
    .config(function ($stateProvider, $urlRouterProvider) {
    
    $urlRouterProvider.otherwise("/")
    
    $stateProvider
    .state('login', {
        url: '/',
        templateUrl: 'login.html',
        controller: 'MainController'
    })
    
    .state('regcodes', {
        url: '/regcodes',
        templateUrl: 'regcodes.html',
        controller: 'RegCodesController'
    })

    .state('customers', {
        url: '/customers',
        templateUrl: 'customers.html',
        controller: 'CustomersController'
    })
    
 });