myApp.controller('confirmdialogController', function ($scope, $location, $log, $cookies, $state, $uibModalInstance, TranslationService, dialogTitle, dialogMessage) {

	if ($cookies.get('language_code')) {
		TranslationService.getTranslation($scope, $cookies.get('language_code'));
	} else {
		TranslationService.getTranslation($scope, "en");
	}

    $scope.dialogTitle = dialogTitle;
    $scope.dialogMessage = dialogMessage;

    $scope.confirm = function () {
        $uibModalInstance.close(1); 
        
    };
    
    $scope.cancel = function () {
        $uibModalInstance.close(0);
    };
    
});