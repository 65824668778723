myApp.controller('GenerateCodeController', function ($scope, $location, $log, $cookies, $state, $uibModalInstance, TranslationService, CustomersModulesService, DeploymentFilesService, RegCodesService, RegCodesHistoryService, regcode, focus, $filter, $mdToast) {

	if ($cookies.get('language_code')) {
		TranslationService.getTranslation($scope, $cookies.get('language_code'));
	} else {
		TranslationService.getTranslation($scope, "en");
	}

	$scope.UserID = $cookies.get('user_unique');
	$scope.loading = true;
	$scope.requiredFieldsError = false;
	$scope.loading = true;

	function loadData() {
		RegCodesService.getRegistrationCodesByCustomer(regcode.COMPANY_ID).then(function (data) {
			
			if (data.length > 0) {
				$scope.CompanyName = regcode.COMPANY_NAME;
				$scope.NamedLicensesQty = data[0].REG_CODE_NAMED_LIC_QTY;
				$scope.ConcurrentLicensesQty = data[0].REG_CODE_CONCURRENT_LIC_QTY;
				$scope.SupportUsers = data[0].REG_CODE_SUPPORT_USERS;
				$scope.ExpiryDate = toDate(data[0].REG_CODE_EXPIRY_DATE);
				$scope.LastGeneratedCode = data[0].REG_CODE_CODE;
				$scope.LastGeneratedCodeDate = data[0].REG_CODE_DATE_GENERATED;
				$scope.Activated = data[0].REG_CODE_ACTIVATED;
				$scope.ActivatedWhen = data[0].REG_CODE_ACTIVATED_WHEN;
				$scope.ActivatedBy = data[0].REG_CODE_ACTIVATED_BY;
			}

			$scope.loading = false;
			focus('opened');

		}).catch(function (error) {
			$scope.loading = false;
		});
	}

	// load form data
	loadData();

  $scope.generateCode = function () {

		// form validation
		if ($scope.NamedLicensesQty == null || $scope.NamedLicensesQty < 0) {
			$scope.requiredFieldsError = true;
			return;
		}

		if ($scope.ConcurrentLicensesQty == null || $scope.ConcurrentLicensesQty < 0) {
			$scope.requiredFieldsError = true;
			return;
		}

		if ($scope.ExpiryDate == null || $scope.ExpiryDate == '') {
			$scope.requiredFieldsError = true;
			return;
		}

		$scope.requiredFieldsError = false;

		//------------------------------------------------------------------------------------------------------------------------------
		// generates new registration code
		//------------------------------------------------------------------------------------------------------------------------------

		getRegistrationCode(regcode.COMPANY_ID, regcode.COMPANY_CODE, $scope.ExpiryDate, $scope.NamedLicensesQty, $scope.ConcurrentLicensesQty, function(registrationCode) {

			// if registration code was generated
			if (registrationCode != "") {

				//------------------------------------------------------------------------------------------------------------------------------
				// inserts history to database
				//------------------------------------------------------------------------------------------------------------------------------

				if (regcode.REG_CODE_CODE != null) {

					// expiry date
					if ($filter('date')(toDate(regcode.REG_CODE_EXPIRY_DATE), "dd/MM/yyyy") != $filter('date')(toDate($scope.ExpiryDate), "dd/MM/yyyy")) {
						var REG_CODE_HISTORY = {};
						REG_CODE_HISTORY.REG_CODE_HIST_REG_ID = regcode.REG_CODE_ID;
						REG_CODE_HISTORY.REG_CODE_HIST_CHANGE_ID = 1;
						REG_CODE_HISTORY.REG_CODE_HIST_OLD_VALUE = $filter('date')(toDate(regcode.REG_CODE_EXPIRY_DATE), "dd/MM/yyyy");
						REG_CODE_HISTORY.REG_CODE_HIST_NEW_VALUE = $filter('date')(toDate($scope.ExpiryDate), "dd/MM/yyyy");
						REG_CODE_HISTORY.REG_CODE_HIST_DATE_AMENDED = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
						REG_CODE_HISTORY.REG_CODE_HIST_AMENDED_BY = $scope.UserID;
						RegCodesHistoryService.saveRegistrationHistory(REG_CODE_HISTORY);
					}

					// named licenses
					if (regcode.REG_CODE_NAMED_LIC_QTY != $scope.NamedLicensesQty) {
						var REG_CODE_HISTORY = {};
						REG_CODE_HISTORY.REG_CODE_HIST_REG_ID = regcode.REG_CODE_ID;
						REG_CODE_HISTORY.REG_CODE_HIST_CHANGE_ID = 2;
						REG_CODE_HISTORY.REG_CODE_HIST_OLD_VALUE = regcode.REG_CODE_NAMED_LIC_QTY;
						REG_CODE_HISTORY.REG_CODE_HIST_NEW_VALUE = $scope.NamedLicensesQty;
						REG_CODE_HISTORY.REG_CODE_HIST_DATE_AMENDED = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
						REG_CODE_HISTORY.REG_CODE_HIST_AMENDED_BY = $scope.UserID;
						RegCodesHistoryService.saveRegistrationHistory(REG_CODE_HISTORY);
					}

					// concurrent licenses
					if (regcode.REG_CODE_CONCURRENT_LIC_QTY != $scope.ConcurrentLicensesQty) {
						var REG_CODE_HISTORY = {};
						REG_CODE_HISTORY.REG_CODE_HIST_REG_ID = regcode.REG_CODE_ID;
						REG_CODE_HISTORY.REG_CODE_HIST_CHANGE_ID = 3;
						REG_CODE_HISTORY.REG_CODE_HIST_OLD_VALUE = regcode.REG_CODE_CONCURRENT_LIC_QTY;
						REG_CODE_HISTORY.REG_CODE_HIST_NEW_VALUE = $scope.ConcurrentLicensesQty;
						REG_CODE_HISTORY.REG_CODE_HIST_DATE_AMENDED = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
						REG_CODE_HISTORY.REG_CODE_HIST_AMENDED_BY = $scope.UserID;
						RegCodesHistoryService.saveRegistrationHistory(REG_CODE_HISTORY);
					}

					// support users
					if (regcode.REG_CODE_SUPPORT_USERS != $scope.SupportUsers) {
						var REG_CODE_HISTORY = {};
						REG_CODE_HISTORY.REG_CODE_HIST_REG_ID = regcode.REG_CODE_ID;
						REG_CODE_HISTORY.REG_CODE_HIST_CHANGE_ID = 4;
						REG_CODE_HISTORY.REG_CODE_HIST_OLD_VALUE = regcode.REG_CODE_SUPPORT_USERS;
						REG_CODE_HISTORY.REG_CODE_HIST_NEW_VALUE = $scope.SupportUsers;
						REG_CODE_HISTORY.REG_CODE_HIST_DATE_AMENDED = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
						REG_CODE_HISTORY.REG_CODE_HIST_AMENDED_BY = $scope.UserID;
						RegCodesHistoryService.saveRegistrationHistory(REG_CODE_HISTORY);
					}
				}
				
				//------------------------------------------------------------------------------------------------------------------------------
				// inserts new code to database
				//------------------------------------------------------------------------------------------------------------------------------

				var REG_CODES = {}
				REG_CODES.REG_CODE_ID = regcode.REG_CODE_ID;
				REG_CODES.REG_CODE_COMPANY_ID = regcode.COMPANY_ID;
				REG_CODES.REG_CODE_CODE = registrationCode;
				REG_CODES.REG_CODE_EXPIRY_DATE = $scope.ExpiryDate.toISOString().replace(/T/, ' ').replace(/\..+/, '');
				REG_CODES.REG_CODE_DATE_GENERATED = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
				REG_CODES.REG_CODE_ACTIVATED = false;
				REG_CODES.REG_CODE_ACTIVATED_WHEN = null;
				REG_CODES.REG_CODE_ACTIVATED_BY = null;
				REG_CODES.REG_CODE_NAMED_LIC_QTY = $scope.NamedLicensesQty;
				REG_CODES.REG_CODE_CONCURRENT_LIC_QTY = $scope.ConcurrentLicensesQty;
				REG_CODES.REG_CODE_SUPPORT_USERS = $scope.SupportUsers;

				console.log("REG_CODES", REG_CODES);


				RegCodesService.saveRegistrationCode(REG_CODES).then(function (response) {
					if (response == null) {
						$mdToast.show({
							template: '<md-toast><div class="md-toast-content"><div><span class="glyphicon glyphicon-remove-sign"></span>  ' + $scope.translation.GENERATING_KEY_ERROR + '</div></div></md-toast>',
							hideDelay: 3000,
							position: 'bottom center'
						});
					}
				});
			} else {
				$mdToast.show({
					template: '<md-toast><div class="md-toast-content"><div><span class="glyphicon glyphicon-remove-sign"></span>  ' + $scope.translation.GENERATING_KEY_ERROR + '</div></div></md-toast>',
					hideDelay: 3000,
					position: 'bottom center'
				});
			}

			$uibModalInstance.close();

		});
  };
  
  $scope.close = function () {
      $uibModalInstance.close();
  };

  $scope.getStyle = function () {
		if ($scope.Activated) {
			return "margin-top: 5px; color: green;";
		} else {
			return "margin-top: 5px; color: red;";
		}
  };

	$scope.getActivationText = function () {
		if ($scope.translation) {
			if ($scope.Activated) {
				var activatedwhen = $filter('date')($scope.ActivatedWhen, "dd/MM/yyyy HH:mm:ss");
				return $scope.translation.ACTIVATED_ON + " " + activatedwhen + " " + $scope.translation.BY + " " + $scope.ActivatedBy;
			} else {
				return $scope.translation.ACTIVATION_PENDING;
			}
		}
  };

	function toDate(input) {
    return new Date(input);
	};

	function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	function decimalToBinary(dec){
    return (dec >>> 0).toString(2);
	}

	function getRegistrationCode(IDCompany, CompanyCode, ExpiryDate, NamedUsers, ConcurrentUsers, cb) {
		try {

			console.log("IDCompany", IDCompany);
			console.log("CompanyCode", CompanyCode);
			console.log("ExpiryDate", ExpiryDate);
			console.log("NamedUsers", NamedUsers);
			console.log("ConcurrentUsers", ConcurrentUsers);

			var sRegistrationCode = "";
			var iKey;

			//------------------------------------------------------------------------------------------------------------------------------
			// Ramdom Key (Between 10 and 26)
			//------------------------------------------------------------------------------------------------------------------------------

			iKey = getRandomInt(10, 26);


			//------------------------------------------------------------------------------------------------------------------------------
			// Part1 - Company Code
			//------------------------------------------------------------------------------------------------------------------------------

			var iNumeric;
			var lowASCII;
			var highASCII;
			var iDifference;

			var sCompanyCodeArray = CompanyCode.split('');

			lowASCII = "A".charCodeAt(0);
			highASCII = "Z".charCodeAt(0);

			sCompanyCodeArray.forEach(function(c) {
				iNumeric = c.charCodeAt(0);

				if (iNumeric < lowASCII || iNumeric > highASCII) {
						sRegistrationCode += iNumeric.toString();
				} else {
						iNumeric = iNumeric + iKey;
						if (iNumeric > highASCII) {
							iDifference = iNumeric - highASCII;
							iNumeric = lowASCII + iDifference;
						}

						sRegistrationCode += iNumeric.toString()
				}
			});


			//------------------------------------------------------------------------------------------------------------------------------
			// Part2 - Expirity Date
			//------------------------------------------------------------------------------------------------------------------------------

			var sDay;
			var sMonth;
			var sYear1;
			var sYear2;

			sDay = $filter('date')(ExpiryDate, "dd");
			sMonth = $filter('date')(ExpiryDate, "MM");
			sYear1 = $filter('date')(ExpiryDate, "yyyy").substring(0, 2);
			sYear2 = $filter('date')(ExpiryDate, "yyyy").substring(2);

			sRegistrationCode += "-";
			sRegistrationCode += (parseInt(sDay) + iKey).toString() + (parseInt(sMonth) + iKey).toString() + (parseInt(sYear1) + iKey).toString() + (parseInt(sYear2) + iKey).toString();


			//------------------------------------------------------------------------------------------------------------------------------
			// Part3 - Named Users
			//------------------------------------------------------------------------------------------------------------------------------

			sRegistrationCode += "-";
			sRegistrationCode += (NamedUsers + iKey).toString();


			//------------------------------------------------------------------------------------------------------------------------------
			// Part4 - Concurrent Users
			//------------------------------------------------------------------------------------------------------------------------------

			sRegistrationCode += "-";
			sRegistrationCode += (ConcurrentUsers + iKey).toString();


			//------------------------------------------------------------------------------------------------------------------------------
			// Part5 - Module Access
			//------------------------------------------------------------------------------------------------------------------------------

			getModulesSequence(IDCompany, function(modules_string) {

				sRegistrationCode += "-";
				sRegistrationCode += parseInt(modules_string, 2).toString();
				
				//------------------------------------------------------------------------------------------------------------------------------
				// Part6 - Squared Key
				//------------------------------------------------------------------------------------------------------------------------------

				sRegistrationCode += "-";
				sRegistrationCode += (iKey * iKey).toString();

				//------------------------------------------------------------------------------------------------------------------------------
				// Final Registration Code Return
				//------------------------------------------------------------------------------------------------------------------------------

				console.log("sRegistrationCode", sRegistrationCode);
				cb(sRegistrationCode);

			});

		} catch(err) {
			cb("");
		}
	}

	function getModulesSequence(IDCompany, cb) {
		CustomersModulesService.getActiveCustomerModulesByCustomer(IDCompany).then(function (data) {
			var ActiveModules = data;

			DeploymentFilesService.getDeploymentFilesByPositionOrder().then(function (data) {
				var DeploymentFiles = data;

				var iMaxPos = 0;

				angular.forEach(DeploymentFiles, function (depfile, key1) {

					angular.forEach(ActiveModules, function (modules, key2) {
						if (modules.REG_CODE_MODULE_DEP_FILE_ID == depfile.DEP_FILE_ID) {
							depfile.REG_CODE_MODULE_ACTIVE = modules.REG_CODE_MODULE_ACTIVE;
						}
					});

					if (depfile.DEP_FILE_REGKEY_POS > iMaxPos) {
						iMaxPos = depfile.DEP_FILE_REGKEY_POS;
					}

				});


				var sModulesSequence = "";

				if (DeploymentFiles.length > 0) {

					var sTmpValue;
					var arr = [];
					arr.length = iMaxPos;

					// first position must be 1 due to conversion from decimal to binary in KBS methods
					arr[0] = "1";

					// initialize array items with 0
					for (i = 1; i < arr.length; i++) { 
						arr[i] = "0";
					}
				
					angular.forEach(DeploymentFiles, function (depfile, key1) {
						sTmpValue =  (depfile.REG_CODE_MODULE_ACTIVE != null && depfile.REG_CODE_MODULE_ACTIVE ? "1" : "0");
						arr[depfile.DEP_FILE_REGKEY_POS] = sTmpValue;
					});

					for (i = 0; i <= iMaxPos; i++) { 
						sModulesSequence += arr[i];
						arr[i] = "0";
					}
				}

				cb(sModulesSequence);

			}).catch(function (error) {
				return cb("");
			});
		}).catch(function (error) {
			return cb("");
		});
	}

});