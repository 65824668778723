myApp.controller('MainController', function ($scope, $http, $location, $log, $cookies, TranslationService, UsersService) {

	if ($cookies.get('language_code')) {
		TranslationService.getTranslation($scope, $cookies.get('language_code'));
	} else {
		TranslationService.getTranslation($scope, "en");
	}

	$scope.inValidUser = false;
	$scope.inErrorMessage = "";
	$scope.importing = false;
	$scope.importingMessage = "";
	$scope.importingProgress = 0;

	$scope.version = "";
	$scope.database = "";

	$http.post('/settings', {}).success(function (settings) {
		$scope.version = settings.version;
		$scope.database = settings.database.database;
	});

	$scope.logIn = function () {

		UsersService.getUserLogin($scope.user, $scope.password).then(function (data) {
			if (data.length > 0) {
				$scope.UserData = data[0];

				if ($scope.UserData.USER_NAME.toUpperCase() == $scope.user.toUpperCase() && $scope.UserData.USER_PASSWORD == $scope.password) {

					//Set 'expires' option in 30 minutes
					var today = new Date();
					var expiresValue = new Date(today);
					expiresValue.setMinutes(today.getMinutes() + 30);

					$cookies.put("logged_in", true, { 'expires': expiresValue });
					$cookies.put("user_unique", $scope.UserData.USER_ID, { 'expires': expiresValue });
					$cookies.put("user_name", $scope.UserData.USER_FULLNAME, { 'expires': expiresValue });
					$cookies.put("database", $scope.database, { 'expires': expiresValue });

					$scope.UserData.USER_AUTHCODE = uuidv4();
					UsersService.updateToken($scope.UserData).then(function (ret) {

						$cookies.put("authcode", $scope.UserData.USER_AUTHCODE, { 'expires': expiresValue });

						UsersService.login();
						$location.path('/regcodes');
	
					});

				} else {
					$scope.inValidUser = true;
					$scope.inErrorMessage = $scope.translation.INVALID_USER_DETAILS_ERROR;
				}
			} else {
				$scope.inValidUser = true;
				$scope.inErrorMessage = $scope.translation.INVALID_USER_DETAILS_ERROR;
			}
		});
	}

	function uuidv4() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	// load data or redirects to login page
	if ($cookies.get('logged_in') && $cookies.get('database')) {
		if ($scope.database == $cookies.get('database')) {
			$location.path('/regcodes');
		} else {
			UsersService.logout();
			$location.path('/');
		}
	} else {
		UsersService.logout();
		$location.path('/');
	}

});
