myApp.service('CustomersService', function ($http, $cookies) {

    this.getCustomers = function () {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            return $http.post("/customers/getAll", { 'auth': $cookies.get('authcode') }).then(function (response) {
                return response.data;
            }, function (response) {
                alert("Error retrieving customers.");
            });
        } else {
            alert("Error retrieving customers.");
        }
    }

    this.saveCustomer = function (customer) {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            return $http.post("/customers/saveData", { 'auth': $cookies.get('authcode'), 'data': customer }).then(function (response) {
                return response.data;
            }, function (response) {
                console.error(response);
                return false;
            });
        } else {
            return false;
        }
    };

});