myApp.service('CustomersModulesService', function ($http, $cookies) {

    this.getCustomerModulesByCustomer = function (customer) {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            var query = { "REG_CODE_MODULE_COMPANY_ID": customer };
            return $http.post("/customermodules/getByQuery", { 'auth': $cookies.get('authcode'), 'query': JSON.stringify(query) }).then(function (response) {
                return response.data;
            }, function (response) {
                alert("Error retrieving customer modules.");
            });
        } else {
            alert("Error retrieving customer modules.");
        }
    };

    this.getActiveCustomerModulesByCustomer = function (customer) {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            var query = { "REG_CODE_MODULE_COMPANY_ID": customer, "REG_CODE_MODULE_ACTIVE": true };
            return $http.post("/customermodules/getByQuery", { 'auth': $cookies.get('authcode'), 'query': JSON.stringify(query) }).then(function (response) {
                return response.data;
            }, function (response) {
                alert("Error retrieving customer modules.");
            });
        } else {
            alert("Error retrieving customer modules.");
        }
    };

    this.getActiveCustomerModules = function () {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            var query = { "REG_CODE_MODULE_ACTIVE": true };
            return $http.post("/customermodules/getByQuery", { 'auth': $cookies.get('authcode'), 'query': JSON.stringify(query) }).then(function (response) {
                return response.data;
            }, function (response) {
                alert("Error retrieving customer modules.");
            });
        } else {
            alert("Error retrieving customer modules.");
        }
    };

    this.saveCustomerModule = function (customermodule) {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            return $http.post("/customermodules/saveData", { 'auth': $cookies.get('authcode'), 'data': customermodule }).then(function (response) {
                return response.data;
            }, function (response) {
                console.error(response);
                return false;
            });
        } else {
            return false;
        }
    };

    this.deleteCustomerModulesByCustomer = function (customer) {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            var query = { "REG_CODE_MODULE_COMPANY_ID": customer };
            return $http.post("/customermodules/deleteData", { 'auth': $cookies.get('authcode'), 'query': query }).then(function (response) {
                return response.data;
            }, function (response) {
                console.error(response);
                return false;
            });
        } else {
            return false;
        }
    }
});