myApp.service('RegCodesService', function ($http, $cookies) {

    this.getRegistrationCodes = function () {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            return $http.post("/regcodes/getAll", { 'auth': $cookies.get('authcode') }).then(function (response) {
                return response.data;
            }, function (response) {
                alert("Error retrieving registration codes.");
            });
        } else {
            alert("Error retrieving registration codes.");
        }
    }

    this.getRegistrationCodesByCustomer = function (customer) {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            var query = { "REG_CODE_COMPANY_ID": customer };
            return $http.post("/regcodes/getByQuery", { 'auth': $cookies.get('authcode'), 'query': query }).then(function (response) {
                return response.data;
            }, function (response) {
                alert("Error retrieving registration codes.");
            });
        } else {
            alert("Error retrieving registration codes.");
        }
    }

    this.saveRegistrationCode = function (regcode) {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            return $http.post("/regcodes/saveData", { 'auth': $cookies.get('authcode'), 'data': regcode }).then(function (response) {
                return response.data;
            }, function (response) {
                console.error(response);
                return false;
            });
        } else {
            return false;
        }
    };

});