myApp.controller('RegCodesController', function ($scope, $location, $log, $cookies, $state, TranslationService, RegCodesService, CustomersService, CustomersModulesService, DeploymentFilesService, UsersService, $mdToast, $uibModal, $window, focus, $http, $filter) {

	if ($cookies.get('language_code')) {
		TranslationService.getTranslation($scope, $cookies.get('language_code'));
	} else {
		TranslationService.getTranslation($scope, "en");
	}

	$scope.UserID = $cookies.get('user_unique');
	$scope.loading = true;


	// REGISTRATION CODES LOADING //

	function loadData() {
		CustomersService.getCustomers().then(function (data) {
			$scope.Customers = data;

			RegCodesService.getRegistrationCodes().then(function (data) {
				$scope.RegistrationCodes = data;

				CustomersModulesService.getActiveCustomerModules().then(function (data) {
					$scope.ActiveModules = data;

					DeploymentFilesService.getDeploymentFiles().then(function (data) {
						$scope.DeploymentFiles = data;

						$scope.RegistrationCodesView = [];

						if ($scope.Customers.length > 0) {
							angular.forEach($scope.Customers, function (customer, key2) {

								var registrationCodeItem = {};
								registrationCodeItem.COMPANY_ID = customer.COMPANY_ID;
								registrationCodeItem.COMPANY_NAME = customer.COMPANY_NAME;
								registrationCodeItem.COMPANY_CODE = customer.COMPANY_CODE;
								registrationCodeItem.COMPANY_EMAIL = customer.COMPANY_EMAIL;
								registrationCodeItem.COMPANY_CONTACT = customer.COMPANY_CONTACT;

								angular.forEach($scope.RegistrationCodes, function (regcode, key1) { 
									if (regcode.REG_CODE_COMPANY_ID == customer.COMPANY_ID) {
										registrationCodeItem.REG_CODE_ID = regcode.REG_CODE_ID;
										registrationCodeItem.REG_CODE_COMPANY_ID = regcode.REG_CODE_COMPANY_ID;
										registrationCodeItem.REG_CODE_CODE = regcode.REG_CODE_CODE;
										registrationCodeItem.REG_CODE_EXPIRY_DATE = regcode.REG_CODE_EXPIRY_DATE;
										registrationCodeItem.REG_CODE_ACTIVE_USERS = regcode.REG_CODE_ACTIVE_USERS;
										registrationCodeItem.REG_CODE_DATE_GENERATED = regcode.REG_CODE_DATE_GENERATED;
										registrationCodeItem.REG_CODE_SELECTED_MODULES = regcode.REG_CODE_SELECTED_MODULES;
										registrationCodeItem.REG_CODE_ACTIVATED = regcode.REG_CODE_ACTIVATED;
										registrationCodeItem.REG_CODE_ACTIVATED_WHEN = regcode.REG_CODE_ACTIVATED_WHEN;
										registrationCodeItem.REG_CODE_ACTIVATED_BY = regcode.REG_CODE_ACTIVATED_BY;
										registrationCodeItem.REG_CODE_NAMED_LIC_QTY = regcode.REG_CODE_NAMED_LIC_QTY;
										registrationCodeItem.REG_CODE_CONCURRENT_LIC_QTY = regcode.REG_CODE_CONCURRENT_LIC_QTY;
										registrationCodeItem.REG_CODE_SUPPORT_USERS = regcode.REG_CODE_SUPPORT_USERS;
									} 
								});

								$scope.RegistrationCodesView.push(registrationCodeItem);

							});
						}
						$scope.loading = false;

					}).catch(function (error) {
						$scope.loading = false;
					});
				}).catch(function (error) {
					$scope.loading = false;
				});
			}).catch(function (error) {
				$scope.loading = false;
			});
		}).catch(function (error) {
			$scope.loading = false;
		});
	}

	function loadActiveModuleString(REG_CODE_COMPANY_ID) {

		var activeModules = [];
		var activeModulesString = "";

		angular.forEach($scope.ActiveModules, function (modules, key2) {
			if (modules.REG_CODE_MODULE_COMPANY_ID == REG_CODE_COMPANY_ID) {
				angular.forEach($scope.DeploymentFiles, function (depfile, key3) {
					if (depfile.DEP_FILE_ID == modules.REG_CODE_MODULE_DEP_FILE_ID) {
						activeModules.push(depfile.DEP_FILE_PLUGIN_DESC);
					}
				});
			}
		});

		if (activeModules.length > 0) {
			activeModules.sort();
		}

		angular.forEach(activeModules, function (module, key2) {
			activeModulesString += module + "%0D";
		});

		activeModulesString = activeModulesString.substring(0, (activeModulesString.length - 3));
		return activeModulesString;
	}

	// load data or redirects to login page
	if ($cookies.get('logged_in') && $cookies.get('database')) {
		$http.post('/settings', {}).success(function (settings) {
			if (settings.database.database == $cookies.get('database')) {
				loadData();
			} else {
				UsersService.logout();
				$location.path('/');
			}
		}).error(function (data) {
			UsersService.logout();
			$location.path('/');
		});
	} else {
		UsersService.logout();
		$location.path('/');
	}



	// FORM FUNCTIONS //

	$scope.viewHistory = function (value) {
		if (value.REG_CODE_CODE != null) {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'regcodeshistory.html',
				controller: 'RegCodesHistoryController',
				size: 'lg',
				windowClass: 'details-modal',
				resolve: {
					regcode: function () {
						return value;
					}
				}
			});
		}
	};

	$scope.generateCode = function (value) {

		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: 'generatecode.html',
			controller: 'GenerateCodeController',
			size: 'md',
			windowClass: 'details-modal',
			resolve: {
				regcode: function () {
					return value;
				}
			}
		});

		modalInstance.result.then(function (dialogResult) {
			loadData();
		});

	};

	$scope.sendEmail = function (regcode) {
		if (regcode.REG_CODE_CODE != null) {
			var link = "mailto:[COMPANY_EMAIL]?subject=KBS Registration Key ([COMPANY_NAME])&body=Dear [COMPANY_CONTACT],%0D%0DFollow your KBS Registration Code: [REG_CODE_CODE],%0D%0D%0DLicense Details%0DCreated Date: [REG_CODE_DATE_GENERATED]%0DExpiry Date: [REG_CODE_EXPIRY_DATE]%0DNumber of Named Licenses: [REG_CODE_NAMED_LIC_QTY]%0DNumber of Concurrent Licenses: [REG_CODE_CONCURRENT_LIC_QTY]%0D%0DModules%0D [REG_CODE_SELECTED_MODULES]%0D%0DBest Regards,%0DKeelings Solutions";
			link = link.replace("[COMPANY_EMAIL]", (regcode.COMPANY_EMAIL == null ? "" : regcode.COMPANY_EMAIL));
			link = link.replace("[COMPANY_NAME]", (regcode.COMPANY_NAME == null ? "" : regcode.COMPANY_NAME));
			link = link.replace("[COMPANY_CONTACT]", (regcode.COMPANY_CONTACT == null ? "Customer" : regcode.COMPANY_CONTACT));
			link = link.replace("[REG_CODE_CODE]", (regcode.REG_CODE_CODE == null ? "" : regcode.REG_CODE_CODE));
			link = link.replace("[REG_CODE_DATE_GENERATED]", (regcode.REG_CODE_DATE_GENERATED == null ? "" : $filter('date')(regcode.REG_CODE_DATE_GENERATED, "dd/MM/yyyy")));
			link = link.replace("[REG_CODE_EXPIRY_DATE]", (regcode.REG_CODE_EXPIRY_DATE == null ? "" : $filter('date')(regcode.REG_CODE_EXPIRY_DATE, "dd/MM/yyyy")));
			link = link.replace("[REG_CODE_NAMED_LIC_QTY]", (regcode.REG_CODE_NAMED_LIC_QTY == null ? "0" : regcode.REG_CODE_NAMED_LIC_QTY));
			link = link.replace("[REG_CODE_CONCURRENT_LIC_QTY]", (regcode.REG_CODE_CONCURRENT_LIC_QTY == null ? "0" : regcode.REG_CODE_CONCURRENT_LIC_QTY));
			link = link.replace("[REG_CODE_SELECTED_MODULES]", loadActiveModuleString(regcode.REG_CODE_COMPANY_ID));

			$window.open(link, "_self");
		}
	};

});