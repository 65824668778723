myApp.controller('RegCodesHistoryController', function ($scope, $location, $log, $cookies, $state, TranslationService, RegCodesHistoryService, RegCodeChangesService, UsersService, $mdToast, $uibModal, $uibModalInstance, $http, regcode, focus) {

  if ($cookies.get('language_code')) {
    TranslationService.getTranslation($scope, $cookies.get('language_code'));
  } else {
    TranslationService.getTranslation($scope, "en");
  }

  $scope.UserID = $cookies.get('user_unique');
  $scope.loading = true;
  $scope.companyName = regcode.COMPANY_NAME;


  // REGISTRATION CODES LOADING // 

  function loadData() {
    RegCodesHistoryService.getRegistrationHistory((regcode.REG_CODE_ID == null ? 0 : regcode.REG_CODE_ID)).then(function (data) {
      $scope.RegistrationCodeHistory = data;

      RegCodeChangesService.getRegistrationCodeChangeReasons().then(function (data) {
        $scope.ChangeReasons = data;

        UsersService.getUsers().then(function (data) {
          $scope.Users = data;

          if ($scope.RegistrationCodeHistory.length > 0) {

            angular.forEach($scope.RegistrationCodeHistory, function (history, key1) {
              angular.forEach($scope.ChangeReasons, function (reason, key2) {
                if (history.REG_CODE_HIST_CHANGE_ID == reason.REG_CODE_CHANGE_ID) {
                  history.REG_CODE_CHANGE_NAME = reason.REG_CODE_CHANGE_NAME;
                }
              });
            });

            angular.forEach($scope.RegistrationCodeHistory, function (history, key1) {
              angular.forEach($scope.Users, function (user, key2) {
                if (history.REG_CODE_HIST_AMENDED_BY == user.USER_ID) {
                  history.USER_FULLNAME = user.USER_FULLNAME;
                }
              });
            });
          }

          $scope.loading = false;


        }).catch(function (error) {
          $scope.loading = false;
        });
      }).catch(function (error) {
        $scope.loading = false;
      });

    }).catch(function (error) {
      $scope.loading = false;
    });
  }

  // load data or redirects to login page
  if ($cookies.get('logged_in') && $cookies.get('database')) {
    $http.post('/settings', {}).success(function (settings) {
      if (settings.database.database == $cookies.get('database')) {
        loadData();
      } else {
        UsersService.logout();
        $location.path('/');
      }
    }).error(function (data) {
      UsersService.logout();
      $location.path('/');
    });
  } else {
    UsersService.logout();
    $location.path('/');
  }



  // FORM FUNCTIONS //

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };

	$scope.trimDate = function (input) {
    if (input.length > 10) {
      return input.substring(0, 10);
    } else {
		  return input;
    }
	};

});