myApp.service('DeploymentFilesService', function ($http, $cookies) {

	this.getDeploymentFiles = function () {
		if ($cookies.get('logged_in') && $cookies.get('authcode')) {
			return $http.post("/deploymentfiles/getAll", { 'auth': $cookies.get('authcode') }).then(function (response) {
				return response.data;
			}, function (response) {
				alert("Error retrieving deployment files.");
			});
		} else {
			alert("Error retrieving deployment files.");
		}
	}

	this.getDeploymentFilesByPositionOrder = function () {
		if ($cookies.get('logged_in') && $cookies.get('authcode')) {
			return $http.post("/deploymentfiles/getAllByPositionOrder", { 'auth': $cookies.get('authcode') }).then(function (response) {
				return response.data;
			}, function (response) {
				alert("Error retrieving deployment files.");
			});
		} else {
			alert("Error retrieving deployment files.");
		}
	}

});