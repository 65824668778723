myApp.service('UsersService', function ($http, $cookies) {

    this.getUsers = function () {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {
            return $http.post("/users/getAll", { 'auth': $cookies.get('authcode') }).then(function (response) {
                return response.data;
            }, function (response) {
                alert("Error retrieving users.");
            });
        } else {
            alert("Error retrieving users.");
        }
    }

    this.getUserLogin = function (user_login, user_pin) {
        var query = { "USER_NAME": user_login, "USER_PASSWORD": user_pin }
        return $http.post("/users/getUserLogin", { 'query': query }).then(function (response) {
            return response.data;
        }, function (response) {
            alert("Error retrieving user.");
        });
    }

    this.saveUser = function (user) {
        if ($cookies.get('logged_in') && $cookies.get('authcode')) {

            if (user.USER_ID == null || user.USER_ID == 0) {
                user.USER_ID = 0;
                user.USER_CREATED_BY = $cookies.get('user_unique');
                user.USER_CREATED_WHEN = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
            }

            return $http.post("/users/saveData", { 'auth': $cookies.get('authcode'), 'data': user }).then(function (response) {
                return response.data;
            }, function (response) {
                console.error(response);
                return false;
            });

        } else {
            return false;
        }
    };

    this.updateToken = function (user) {
        return $http.post("/users/updateToken", { 'data': user }).then(function (response) {
            return response.data;
        }, function (response) {
            console.error(response);
            return false;
        });
    };

    self = this;
    self.userStatus = { logged_in: false, user_name: '' };

    if ($cookies.get('logged_in')) {
        self.userStatus.logged_in = $cookies.get('logged_in');
        self.userStatus.user_unique = $cookies.get('user_unique');
        self.userStatus.user_name = $cookies.get('user_name');
    }

    this.login = function () {
        self.userStatus.logged_in = $cookies.get('logged_in');
        self.userStatus.user_unique = $cookies.get('user_unique');
        self.userStatus.user_name = $cookies.get('user_name');
    };

    this.logout = function () {

        var user_data =  {
            USER_AUTHCODE: null,
            USER_ID: self.userStatus.user_unique
        }
 
        this.updateToken(user_data).then(function (ret) {

            $cookies.remove("logged_in");
            $cookies.remove("user_unique");
            $cookies.remove("user_name");
            $cookies.remove("authcode");
    
            self.userStatus.logged_in = false;
            self.userStatus.user_name = '';
    
        });

    }
});