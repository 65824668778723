myApp.controller('CustomersController', function ($scope, $location, $log, $cookies, $state, TranslationService, CustomersService, $mdToast, $uibModal, focus, $http, UsersService) {

	if ($cookies.get('language_code')) {
		TranslationService.getTranslation($scope, $cookies.get('language_code'));
	} else {
		TranslationService.getTranslation($scope, "en");
	}

	$scope.UserID = $cookies.get('user_unique');
	$scope.loading = true;

	$scope.addNew = false;
	$scope.newItem = {};
	$scope.requiredFieldsError = false;
	$scope.companyCodeAlreadyUsedError = false;


	// CUSTOMERS LOADING //

	function loadData() {
		CustomersService.getCustomers().then(function (data) {
			$scope.Customers = data;
			$scope.loading = false;
		}).catch(function (error) {
			$scope.loading = false;
		});
	}

	// load data or redirects to login page
	if ($cookies.get('logged_in') && $cookies.get('database')) {
		$http.post('/settings', {}).success(function (settings) {
			if (settings.database.database == $cookies.get('database')) {
				loadData();
			} else {
				UsersService.logout();
				$location.path('/');
			}
		}).error(function (data) {
			UsersService.logout();
			$location.path('/');
		});
	} else {
		UsersService.logout();
		$location.path('/');
	}



	// FORM FUNCTIONS //

	$scope.add = function () {
		angular.forEach($scope.Customers, function (value, key) {
			value.editing = false;
		});

		$scope.addNew = true;
		$scope.newItem = {};

		focus('newItemAdded');
	};

	$scope.cancelAdd = function () {
		$scope.addNew = false;
		$scope.newItem = {};
		$scope.requiredFieldsError = false;
		$scope.companyCodeAlreadyUsedError = false;
	};

	$scope.edit = function (COMPANY_ID) {

		$scope.cancelAdd();
		$scope.requiredFieldsError = false;
		$scope.companyCodeAlreadyUsedError = false;

		angular.forEach($scope.Customers, function (value, key) {
			value.editing = false;
		});

		angular.forEach($scope.Customers, function (value, key) {
			if (value.COMPANY_ID == COMPANY_ID) {
				value.editing = true;
			}
		});

		focus('newItemAdded');
	};

	$scope.cancelEdit = function () {

		$scope.requiredFieldsError = false;
		$scope.companyCodeAlreadyUsedError = false;

		angular.forEach($scope.Customers, function (value, key) {
			value.editing = false;
		});

		loadData();
	};

	$scope.editModules = function (value) {

		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: 'customermodules.html',
			controller: 'CustomerModulesController',
			size: 'lg',
			windowClass: 'details-modal',
			resolve: {
				customer: function () {
					return value;
				}
			}
		});

	};


	// FORM FUNCTIONS CALLING DATABASE //

	$scope.insert = function () {

		// form validation
		if ($scope.newItem.COMPANY_NAME == null || $scope.newItem.COMPANY_NAME == "") {
			$scope.requiredFieldsError = true;
			return;
		}

		if ($scope.newItem.COMPANY_CODE == null || $scope.newItem.COMPANY_CODE == "") {
			$scope.requiredFieldsError = true;
			return;
		} else {
			$scope.newItem.COMPANY_CODE = $scope.newItem.COMPANY_CODE.toUpperCase();
		}

		if ($scope.newItem.COMPANY_DATABASE == null || $scope.newItem.COMPANY_DATABASE == "") {
			$scope.requiredFieldsError = true;
			return;
		}

		angular.forEach($scope.Customers, function (value, key) {
			if (value.COMPANY_CODE == $scope.newItem.COMPANY_CODE) {
				$scope.companyCodeAlreadyUsedError = true;
				return;
			}
		});


		$scope.requiredFieldsError = false;

		// set created by and created when values
		$scope.newItem.COMPANY_CREATED_WHEN = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
		$scope.newItem.COMPANY_CREATED_BY = $scope.UserID;


		// save data
		CustomersService.saveCustomer($scope.newItem)
			.then(function (response) {
				if (response != null) {
					$scope.addNew = false;
					$scope.newItem = {};
				} else {
					$mdToast.show({
						template: '<md-toast><div class="md-toast-content"><div><span class="glyphicon glyphicon-remove-sign"></span>  ' + $scope.translation.CUSTOMER_SAVED_ERROR + '</div></div></md-toast>',
						hideDelay: 3000,
						position: 'bottom center'
					});
				}
			})
			.finally(function () {
				// refresh screen data
				loadData();
			});
	};

	$scope.update = function (COMPANY_ID) {

		angular.forEach($scope.Customers, function (value, key) {
			if (value.COMPANY_ID == COMPANY_ID) {

				// form validation
				if (value.COMPANY_NAME == null || value.COMPANY_NAME == "") {
					$scope.requiredFieldsError = true;
					return;
				}

				if (value.COMPANY_CODE == null || value.COMPANY_CODE == "") {
					$scope.requiredFieldsError = true;
					return;
				} else {
					value.COMPANY_CODE = value.COMPANY_CODE.toUpperCase();
				}

				if (value.COMPANY_DATABASE == null || value.COMPANY_DATABASE == "") {
					$scope.requiredFieldsError = true;
					return;
				}

				$scope.companyCodeAlreadyUsedError = false;
				
				angular.forEach($scope.Customers, function (value2, key) {
					if (value2.COMPANY_ID != value.COMPANY_ID && value2.COMPANY_CODE == value.COMPANY_CODE) {
						$scope.companyCodeAlreadyUsedError = true;
					}
				});

				if ($scope.companyCodeAlreadyUsedError) {
					return;
				}

				$scope.requiredFieldsError = false;

				console.log("update", value);

				// save data
				CustomersService.saveCustomer(value)
					.then(function (response) {
						if (response == null) {
							$mdToast.show({
								template: '<md-toast><div class="md-toast-content"><div><span class="glyphicon glyphicon-remove-sign"></span>  ' + $scope.translation.CUSTOMER_SAVED_ERROR + '</div></div></md-toast>',
								hideDelay: 3000,
								position: 'bottom center'
							});
						}
					})
					.finally(function () {
						// refresh screen data
						loadData();
					});

				return;
			}
		});
	};

	$scope.delete = function (COMPANY_ID) {

		$scope.cancelAdd();
		$scope.cancelEdit();

		var modalInstance = $uibModal.open({
			animation: true,
			templateUrl: 'confirmdialog.html',
			controller: 'confirmdialogController',
			resolve: {
				dialogTitle: function () {
					return $scope.translation.DELETE_CUSTOMER;
				},
				dialogMessage: function () {
					return $scope.translation.DELETE_CUSTOMER_CONFIRM_TEXT;
				}
			}
		});

		modalInstance.result.then(function (dialogResult) {

			if (dialogResult == 1) {

				angular.forEach($scope.QuestionLogic, function (value, key) {
					if (value.question_logic_unique == question_logic_unique) {
						// delete data
						CustomersService.deleteCustomer(COMPANY_ID)
							.then(function (response) {
								if (response) {
									$mdToast.show({
										template: '<md-toast><div class="md-toast-content"><div><span class="glyphicon glyphicon-ok-sign"></span>  ' + $scope.translation.CUSTOMER_DELETED_SUCCESS + '</div></div></md-toast>',
										hideDelay: 3000,
										position: 'bottom center'
									});
								} else {
									$mdToast.show({
										template: '<md-toast><div class="md-toast-content"><div><span class="glyphicon glyphicon-remove-sign"></span>  ' + $scope.translation.CUSTOMER_DELETED_ERROR + '</div></div></md-toast>',
										hideDelay: 3000,
										position: 'bottom center'
									});
								}
							})
							.finally(function () {
								// refresh screen data
								loadData();
							});
						return;
					}
				});
			}
		});
	};

	$scope.toDate = function (input) {
		return new Date(input);
	};

	$scope.toFloat = function (input) {
		return parseFloat(input);
	};

});