myApp.controller('CustomerModulesController', function ($scope, $location, $log, $cookies, $state, TranslationService, DeploymentFilesService, CustomersModulesService, UsersService, $mdToast, $uibModal, $uibModalInstance, $http, customer, focus) {

  if ($cookies.get('language_code')) {
    TranslationService.getTranslation($scope, $cookies.get('language_code'));
  } else {
    TranslationService.getTranslation($scope, "en");
  }

  $scope.UserID = $cookies.get('user_unique');
  $scope.loading = true;
  $scope.companyName = customer.COMPANY_NAME;


  // CUSTOMER MODULES LOADING // 

  function loadData() {
    DeploymentFilesService.getDeploymentFiles().then(function (data) {
      $scope.DeploymentFiles = data;

      CustomersModulesService.getCustomerModulesByCustomer(customer.COMPANY_ID).then(function (data) {
        $scope.CustomerModules = data;

        if ($scope.DeploymentFiles.length > 0) {

          angular.forEach($scope.DeploymentFiles, function (depfile, key1) {
            angular.forEach($scope.CustomerModules, function (custmodule, key2) {
              if (depfile.DEP_FILE_ID == custmodule.REG_CODE_MODULE_DEP_FILE_ID) {
                depfile.REG_CODE_MODULE_COMPANY_ID = custmodule.REG_CODE_MODULE_COMPANY_ID;
                depfile.REG_CODE_MODULE_ACTIVE = custmodule.REG_CODE_MODULE_ACTIVE;
              }
            });
          });

        }

        $scope.loading = false;

      }).catch(function (error) {
        $scope.loading = false;
      });

    }).catch(function (error) {
      $scope.loading = false;
    });
  }

  // load data or redirects to login page
  if ($cookies.get('logged_in') && $cookies.get('database')) {
    $http.post('/settings', {}).success(function (settings) {
      if (settings.database.database == $cookies.get('database')) {
        loadData();
      } else {
        UsersService.logout();
        $location.path('/');
      }
    }).error(function (data) {
      UsersService.logout();
      $location.path('/');
    });
  } else {
    UsersService.logout();
    $location.path('/');
  }



  // FORM FUNCTIONS //

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.checkedChanged = function (depfile) {

    var REG_CODE_COMPANY_MODULES = {};
    REG_CODE_COMPANY_MODULES.REG_CODE_MODULE_COMPANY_ID = customer.COMPANY_ID;
    REG_CODE_COMPANY_MODULES.REG_CODE_MODULE_DEP_FILE_ID = depfile.DEP_FILE_ID;
    REG_CODE_COMPANY_MODULES.REG_CODE_MODULE_ACTIVE = depfile.REG_CODE_MODULE_ACTIVE;
    REG_CODE_COMPANY_MODULES.REG_CODE_MODULE_CREATED_WHEN = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    REG_CODE_COMPANY_MODULES.REG_CODE_MODULE_CREATED_BY = $scope.UserID;

    CustomersModulesService.saveCustomerModule(REG_CODE_COMPANY_MODULES);
  };

  
});