myApp.service('TranslationService', function ($http, $cookies, $resource) {

    var language_code = $cookies.get('language_code');

    this.getTranslation = function ($scope, language) {
        var languageFilePath = 'translation_' + language + '.json';
        $resource(languageFilePath).get(function (data) {
            $scope.translation = data;
        });
    }
});